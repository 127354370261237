<template>
  <b-modal title="Agregar Productos Internos" size="lg"
    hide-footer
    id="theModalProductoInterno">
    <form>
      <div class="row form-group">
        <label class="col-sm-2 col-lg-1 col-form-label">Producto: </label>
        <div class="col-sm-10 col-lg-11">
          <multiselect2
          track-by="nombre"
          label="nombre"
          placeholder="Seleccione Producto"
          :options="productos"
          :allow-empty="false"
          :show-labels="false"
          >
          </multiselect2>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-right">
          <button
              class="btn btn-outline-dark mr-2"
              type="button"
              @click="cerrarModal">
              Cancelar
          </button>
          <button
              class="btn btn-success"
              type="button"
              @click="cerrarModal">
              Agregar
          </button>
      </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import Multiselect2 from '@/components/util/vue-multiselect2';

export default {
  name: 'TheModalProductoInterno',
  data() {
    return {
      productos: [],
    };
  },
  methods: {
    cerrarModal() {
      this.$bvModal.hide('theModalProductoInterno');
    },
  },
  components: {
    Multiselect2,
  },
};
</script>
