<template>
  <div>
    <div>
      <div class="breadcrumb">
        <h1>Editar Asistencia Médico</h1>
      </div>
      <div class="row mb-2">
        <div class="col-sm-12">
          <div class="card text-left">
            <div class="card-header">
              <div class="col-sm-5">
                <button type="submit"
                class="btn bg-custom-green mr-2 rounded"
                >
                Guardar
                </button>
                <router-link :to="{ name: 'atencion-medico-index' }">
                  <button class="btn btn-white border border-light rounded">
                    Regresar
                  </button>
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="row pt-3">
                <div class="col d-flex"><strong>Nº: 1 </strong>
                  <div class="px-3">
                    Origen: 332/22 (12/01/2022 - Hora: 12:33)
                  </div>
                </div>
                <div class="col text-right"><strong>Nº HC: 451</strong></div>
              </div>
              <div class="row pb-3">
                <div class="col-12"><strong>Paciente:</strong>
                  MELANY FELICIDAD MACIAS LOZA - Edad: 23 años
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-inline pt-2">
                    <label for="tipoConsulta" class="pr-3">Tipo dee Consulta: </label>
                    <button class="btn bg-custom-green mr-2"
                    v-if="tipoConsulta === 1"
                    @click="tipoConsulta = 2">
                      Nuevo
                    </button>
                    <button class="btn btnOrange mr-2"
                    v-if="tipoConsulta === 2"
                    @click="tipoConsulta = 1">
                      Reconsulta
                    </button>

                    <button class="btn btn-default mr-2">
                      Historial Clinico
                    </button>
                    <button class="btn btn-default mr-2">
                      Signos Vitales
                    </button>
                  </div>
                  <div class="form-group pt-2">
                    <label for="motivoConsulta">Motivo de Consulta:</label>
                    <textarea name="" id="motivoConsulta"
                      cols="20" rows="10" class="form-control">
                    </textarea>
                  </div>
                  <div class="form-group pt-2">
                    <label for="motivoConsulta">Examen Físico:</label>
                    <textarea name="" id="motivoConsulta" cols="20"
                      rows="10" class="form-control">
                    </textarea>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="examenComplementario" class="pr-3">Examen Complementarios: </label>
                    <div class="row">
                      <div class="col-9">
                        <input type="text" id="examenComplementario" class="form-control">
                      </div>
                      <div class="col-3 text-right">
                        <button class="btn btn-secondary btn-sm">Generar Orden</button>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-body border border-light">
                      <div class="form-inline pb-3">
                        <div class="pr-4">Diagnostico 1:</div>
                        <div class="pr-4">
                          <button class="btn bg-custom-green">Presuntivo</button>
                        </div>
                        <div>
                          <button class="btn btn-secondary">Definitivo</button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <select name="diagnostico" id="" class="custom-select"
                            aria-placeholder="Seleccione">
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card mt-4">
                    <div class="card-body border border-light">
                      <div class="form-inline pb-3">
                        <div class="pr-4">Diagnostico 2:</div>
                        <div class="pr-4">
                          <button class="btn bg-custom-green">Presuntivo</button>
                        </div>
                        <div><button class="btn btn-secondary">Definitivo</button></div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <select name="diagnostico" id="" class="custom-select"
                            aria-placeholder="Seleccione">
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card mt-4">
                    <div class="card-body border border-light">
                      <div class="form-inline pb-3">
                        <div class="pr-4">Diagnostico 3:</div>
                        <div class="pr-4">
                          <button class="btn bg-custom-green">Presuntivo</button>
                        </div>
                        <div><button class="btn btn-secondary">Definitivo</button></div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <select name="diagnostico" id="" class="custom-select"
                            aria-placeholder="Seleccione">
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-3">
                  <TheTableMedicamentos/>
                </div>
                <div class="col-12 pt-3">
                  <div class="d-flex justify-content-end">
                    <div class="pr-3"><button class="btn btn-secondary">Cancelar</button></div>
                    <div><button class="btn bg-custom-green">Guardar</button></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheTableMedicamentos from '../components/TheTableMedicamento.vue';

export default {
  name: 'asistenciaCrate',
  data() {
    return {
      tipoConsulta: 1,
    };
  },
  components: {
    TheTableMedicamentos,
  },
};
</script>

<style scoped>
.btnOrange {
  color: white;
  background-color: #F4B375;
}
</style>
