<template>
  <div class="col-sm-12 border border-light">
    <div class="form-group row">
      <div class="col-sm-8 pt-2">
        <div><span class="pr-3"><strong>Medicamentos</strong></span>
          <button class="btn bg-custom-green btn-sm text-white mr-2"
          @click="openModalProductoInterno" >Internos</button>
          <button class="btn bg-custom-green btn-sm text-white mr-2"
          @click="openModalProductoLinae">Linae</button>
        </div>
        <div>
          <table class="table table-striped table-hover">
            <tr>
              <th width="2%" class="text-center"></th>
              <th width="2%" class="text-center">Cantidad</th>
              <th width="20%" class="text-center">Medicamento</th>
              <th width="10%" class="text-center">Via</th>
              <th width="10%" class="text-center">Dosis</th>
            </tr>
            <tr v-for="(tratamiento, index) in tratamientos" :key="index">
              <td>
                <i class="nav-icon i-Close-Window font-weight-bold text-danger"
                title="eliminar"
                  @click="eliminar(index)"
                  style="cursor: pointer">
                </i>
              </td>
              <td class="text-center">{{tratamiento.cantidad}}</td>
              <td class="text-center">{{tratamiento.producto}}</td>
              <td class="text-center">
                <div class="col-sm-12">
                  <multiselect2
                    :options="administracionMedicamentos"
                    v-model="administracionSelected"
                    placeholder=""
                    label="nombre"
                    track-by="id"
                    select-label=""
                    deselect-label="X">
                  </multiselect2>
                </div>
              </td>
              <td class="text-center">
                <input type="text" class="form-control">
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-sm-4 pt-5 mt-1">
        <b> <label for="observacion">Observacion</label> </b>
        <textarea name="observacion" id="observacion" cols="10" rows="2"
          class="form-control">
        </textarea>
      </div>
    </div>
    <TheModalProductInterno/>
    <TheModalProductoLinae/>
  </div>
</template>

<script>
import Multiselect2 from '@/components/util/vue-multiselect2';
import TheModalProductInterno from './TheModalProductoInternos.vue';
import TheModalProductoLinae from './TheModalProductoLinae.vue';

export default {
  name: 'TheTableMedicamento',
  data() {
    return {
      tratamientos: [
        {
          cantidad: 1,
          producto: 'Medicamento 1',
          via: 'Oral',
          dosis: '500gr.',
        },
        {
          cantidad: 40,
          producto: 'Medicamento 456',
          via: 'Oral',
          dosis: '500gr.',
        },
        {
          cantidad: 60,
          producto: 'Medicamento 45644',
          via: 'Oral',
          dosis: '500gr.',
        },
      ],
      administracionMedicamentos: [
        {
          id: 1,
          nombre: 'Oral',
        },
        {
          id: 2,
          nombre: 'Intramuscular',
        },
        {
          id: 3,
          nombre: 'Endovenosa',
        },
      ],
      administracionSelected: null,
    };
  },
  methods: {
    eliminar(index) {
      this.tratamientos.splice(index, 1);
    },
    openModalProductoInterno() {
      this.$bvModal.show('theModalProductoInterno');
    },
    openModalProductoLinae() {
      this.$bvModal.show('theModalProductoLinae');
    },
  },
  components: {
    Multiselect2,
    TheModalProductInterno,
    TheModalProductoLinae,
  },
};
</script>
